/*
 * @Author: zhengda.lv
 * @Date: 2021-10-30 10:15:26
 * @LastEditTime: 2021-11-26 16:45:46
 * @LastEditors: zhengda.lv
 * @Description:
 */
import { fetchGet, fetchPost, fetchPostPic } from '../index';

//? 按groupCode查询数据字典
export const findByGroupCode = (params: any): Promise<any> => {
    return fetchGet(`/claimapi/claimDictionary/findByGroupCode`, { params });
};

//[OASIS-9755]批量新增
export const batchSave = (params: any): Promise<any> => {
    return fetchPostPic(`/claimapi/exemption/batchSave`, params);
};

// 获取所有已激活的经销商信息
export const getAllDealerInfos = (): Promise<any> => {
    return fetchGet(`/dapi/noAuth/dealer/allDealerInfos`);
};
