
import { defineComponent, ref, reactive, nextTick, watch } from "vue";
import { useRouter } from "vue-router";
import { useStore } from "vuex";
import downloadFile from "@/utils/claim/downloadFile";
import { message } from "ant-design-vue";
import moment from "moment";
// import EditEnquiry from "@/views/Claim/EnquiryProgram/EditEnquiryProgram";
import {
  getExemptionLst,
  exemptionDownload,
  exemptionDelete,
} from "@/API/checking/exemption";
import { findByGroupCode } from "@/API/checking/exemptionSetup";
import { AFCandLCApproveTableData } from "@/views/Payment/Mgmt/types";
import { ProgramType } from "@/views/Delay/type";
import { getBuSubregion } from "@/API/salesReporting/claimCheckingReport";
import {
  //getRegionApi,
  //getDealerGroup,
  getOfferType,
  getCustomerType,
} from "@/API/claimDelay/common";
const tableWidth = window.innerWidth;
const columns = reactive([
  {
    title: "Exemption Code",
    dataIndex: "exemptionCode",
    width: 150,
  },
  {
    title: "Exemption Name",
    dataIndex: "exemptionName",
    width: 150,
  },
  {
    title: "Business Unit",
    dataIndex: "bu",
    width: 150,
  },
  {
    title: "Eligible Dealer",
    dataIndex: "eligibleDealerValue",
    width: 150,
    slots: { customRender: "eligibleDealer" },
  },
  {
    title: "Dealer name(CN)",
    dataIndex: "eligibleDealerNameCn",
    width: 350,
    slots: { customRender: "dealerNameCn" },
  },
  {
    title: "Eligible Program",
    dataIndex: "eligibleProgramValue",
    width: 150,
    slots: { customRender: "eligibleProgram" },
  },
  { title: "VIN Count", dataIndex: "vinCount", width: 150 },
  {
    title: "Amount",
    dataIndex: "amountWithoutVat",
    width: 150,
  },
  {
    title: "Exemption Reason",
    dataIndex: "exemptionReason",
    width: 150,
  },
  {
    title: "Request Date",
    dataIndex: "requestDate",
    width: 150,
    slots: { customRender: "requestdate" },
  },
  { title: "End Date", dataIndex: "endDate", width: 150 },
  {
    title: "Exemption Period",

    width: 200,
    slots: { customRender: "exemptionPeriod" },
  },
  {
    title: "Document",
    dataIndex: "comments",
    width: 150,
    slots: { customRender: "document" },
  },
  {
    title: "Operation",
    dataIndex: "comments",
    width: 150,
    slots: { customRender: "operation" },
  },
]);
export default defineComponent({
  components: {},
  setup() {
    const tableHeight = ref();
    const searchCon = ref();
    const queryListData = ref<any[]>([]);

    const listParams = reactive<any>({
      exemptionCode: "",
      exemptionName: "",
      programCode: "",
      programCategory: "",
      customerType: "",
      exemptionReason: "",
      dealerCode: "",
      dealerGroup: "",
      dealerNameCn: "",
      requestDateStart: "",
      requestDateEnd: "",
      bu: "",
      region: [],
      current: 0,
      size: 0,
    });
    //分页组件
    const pagination = reactive({
      pageSize: 20,
      currentPage: 0,
      total: 0,
    });

    const store = useStore();

    const queryListParams = reactive<any>({
      exemptionCode: "",
      exemptionName: "",
      programCode: "",
      programCategory: null,
      customerType: null,
      exemptionReason: null,
      dealerCode: "",
      dealerGroup: "",
      dealerNameCn: "",
      requestDateStart: "",
      requestDateEnd: "",
      requestDate: "",
      bu: null,
      buName: null,
      region: [],
    });
    nextTick(() => {
      (document.getElementsByClassName("table-box")[0] as any).style.height =
        window.innerHeight - 280 - searchCon.value.$el.scrollHeight + "px";
      const height = (document.getElementsByClassName("table-box")[0] as any)
        .style.height;
      const heightNum = parseInt(height.substr(0, height.indexOf("px")));
      // tableHeight.value = heightNum - 110;
      tableHeight.value = heightNum - 80;
    });
    const rowSelection = reactive<{
      selectedRowKeys: [];
      selectedRowData: AFCandLCApproveTableData[];
      onChange: Function;
      columnWidth: string;
    }>({
      onChange: (
        selectedRowKeys: [],
        selectedRows: AFCandLCApproveTableData[]
      ) => {
        rowSelection.selectedRowKeys = selectedRowKeys;
        rowSelection.selectedRowData = selectedRows;
      },
      selectedRowKeys: [],
      selectedRowData: [],
      columnWidth: "40px",
    });
    // 获取params
    const getParams = () => {
      listParams.exemptionCode = "";
      listParams.exemptionName = "";
      listParams.programCode = "";
      listParams.programCategory = "";
      listParams.customerType = "";
      listParams.exemptionReason = "";
      listParams.dealerCode = "";
      listParams.dealerGroup = "";
      listParams.dealerNameCn = "";
      listParams.requestDateStart = "";
      listParams.requestDateEnd = "";
      listParams.bu = queryListParams.buName || "";
      listParams.region = queryListParams.region;
      listParams.current = pagination.currentPage;
      listParams.size = pagination.pageSize;

      listParams.exemptionCode = queryListParams.exemptionCode
        ? queryListParams.exemptionCode
        : "";
      listParams.exemptionName = queryListParams.exemptionName
        ? queryListParams.exemptionName
        : "";
      listParams.programCode = queryListParams.programCode
        ? queryListParams.programCode
        : "";
      listParams.programCategory =
        queryListParams.programCategory &&
        queryListParams.programCategory !== null
          ? (queryListParams.programCategory as string)
          : "";
      listParams.customerType =
        queryListParams.customerType && queryListParams.customerType !== null
          ? (queryListParams.customerType as string)
          : "";
      listParams.exemptionReason =
        queryListParams.exemptionReason &&
        queryListParams.exemptionReason !== null
          ? (queryListParams.exemptionReason as string)
          : "";
      listParams.dealerCode = queryListParams.dealerCode
        ? queryListParams.dealerCode
        : "";
      listParams.dealerGroup = queryListParams.dealerGroup
        ? queryListParams.dealerGroup
        : "";
      listParams.dealerNameCn = queryListParams.dealerNameCn
        ? queryListParams.dealerNameCn
        : "";
      listParams.requestDateStart = queryListParams.requestDateStart
        ? queryListParams.requestDateStart
        : "";
      listParams.requestDateEnd = queryListParams.requestDateEnd
        ? queryListParams.requestDateEnd
        : "";
      /*if (queryListParams.exemptionReason&&queryListParams.exemptionReason!==null){
                listParams.exemptionReason = queryListParams.exemptionReason
            }else {
                listParams.exemptionReason = ''
            }*/
    };
    const businessUnitSelect = store.state.user.buList.filter((bu: any) => {
      return bu.parentId === null;
    });
    const canSelectRegion = ref(false);
    watch(
      () => queryListParams.bu,
      (newVal) => {
        if (newVal) {
          canSelectRegion.value = true;
        } else {
          canSelectRegion.value = false;
        }
      }
    );
    const regionLst = ref<any>([]);
    const dogetRegion = () => {
      regionLst.value = [];
      getBuSubregion({ buId: queryListParams.bu }).then((res) => {
        queryListParams.region = [];
        const sortArr: any[] = [[], [], [], [], []];
        const maps = {
          n: 0,
          e: 1,
          s: 3,
          w: 2,
        };
        res.forEach((region: any) => {
          const firstLetter = (
            region.regionEn[0] as string
          ).toLocaleLowerCase();
          if (firstLetter in maps) {
            sortArr[maps[firstLetter]].push(region);
          } else {
            sortArr[4].push(region);
          }
        });
        regionLst.value = sortArr.flat();
      });
    };
    const getBuName = (val: any, opt: any) => {
      console.log(val, opt);
      queryListParams.buName = opt.name;
    };

    const programTypeList = ref<ProgramType[]>([]);
    const genProgramTypeList = () => {
      getOfferType().then((res: any) => {
        const offerTypeList = res.incentiveCategory.reduce(
          (totalList: any, arr: any) => {
            return [...totalList, ...arr.offerType];
          },
          []
        );
        programTypeList.value = offerTypeList;
      });
    };
    genProgramTypeList();

    const customerTypeList = ref([]);
    const genCustomerTypeList = () => {
      getCustomerType().then((res: any) => {
        customerTypeList.value = res;
      });
    };
    genCustomerTypeList();

    const reasonList = ref([]);
    const getReasonList = () => {
      findByGroupCode({ groupCode: "exemption_reason" }).then((res: any) => {
        reasonList.value = res;
      });
    };
    getReasonList();

    const doGetListData = () => {
      getParams();
      getExemptionLst(listParams).then((res) => {
        queryListData.value = res.data;
        pagination.total = parseInt(res.totalNum);
      });
    };
    doGetListData();

    //更改分页组件，包括：直接跳转到指定页，上一页下一页
    const changePagination = (page: number, pagesize: number) => {
      pagination.pageSize = pagesize;
      pagination.currentPage = page - 1 > 0 ? page - 1 : 0;
      doGetListData();
    };
    //更改每页size
    const changePageSize = (page: number, pagesize: number) => {
      pagination.pageSize = pagesize;
      pagination.currentPage = page - 1 > 0 ? page - 1 : 0;
      doGetListData();
    };
    //下载
    const exportLst = () => {
      getParams();
      const paramsConfig = {
        url: `/claimapi/exemption/export/by/query`,
        method: "get",
        params: listParams,
      };
      downloadFile(paramsConfig, "application/vnd-excel; char-set=UTF-8");
    };
    const handleSelectRequestDate = () => {
      queryListParams.requestDateStart =
        queryListParams.requestDate && queryListParams.requestDate.length > 0
          ? queryListParams.requestDate[0]
          : "";
      queryListParams.requestDateEnd =
        queryListParams.requestDate && queryListParams.requestDate.length > 0
          ? queryListParams.requestDate[1]
          : "";
    };
    const handlExemptionFile = (record: any) => {
      exemptionDownload(record.id).then((res: any) => {
        const eleLink = document.createElement("a");
        eleLink.download = res.fileName;
        eleLink.style.display = "none";
        eleLink.href = res.downloadUrl;
        document.body.appendChild(eleLink);
        eleLink.click();
        document.body.removeChild(eleLink);
      });
    };

    const router = useRouter();
    const goToSetup = () => {
      router.push({ path: "/checking/exemptionSetup" });
    };
    const reset = () => {
      queryListParams.exemptionCode = "";
      queryListParams.exemptionName = "";
      queryListParams.programCode = "";
      queryListParams.programCategory = null;
      queryListParams.customerType = null;
      queryListParams.exemptionReason = null;
      queryListParams.dealerCode = "";
      queryListParams.dealerGroup = "";
      queryListParams.dealerNameCn = "";
      queryListParams.requestDateStart = "";
      queryListParams.requestDateEnd = "";
      queryListParams.requestDate = "";
      queryListParams.bu = null;
      queryListParams.buName = null;
      queryListParams.region = [];
    };

    const renderDealer = (record: any) => {
      if (record.eligibleDealerKeyText === "Dealer Code") {
        return record.eligibleDealerValue;
      }
      if (
        record.eligibleDealerKeyText === "Region" ||
        record.eligibleDealerKeyText === "Dealer Group"
      ) {
        return record.eligibleDealerNameEn;
      }
      return "N/A";
    };

    const renderDealerName = (record: any) => {
      if (record.eligibleDealerKeyText === "Dealer Code") {
        return record.eligibleDealerNameCn;
      }
      return "";
    };

    const renderEligibleProgram = (record: any) => {
      if (record.eligibleProgramKeyText === "Program Code") {
        return record.eligibleProgramValue;
      }
      if (
        record.eligibleProgramKeyText === "Program Category" ||
        record.eligibleProgramKeyText === "Customer Type"
      ) {
        return record.eligibleProgramNameEn;
      }
      return "N/A";
    };

    const deleteRecord = (record: any) => {
      exemptionDelete(record.id).then((_: any) => {
        message.success("Delete success!");
        doGetListData();
      });
    };

    return {
      handlExemptionFile,
      handleSelectRequestDate,
      exportLst,
      changePageSize,
      changePagination,
      getParams,
      pagination,
      doGetListData,
      rowSelection,
      searchCon,
      tableHeight,
      queryListData,
      queryListParams,
      tableWidth,
      columns,
      businessUnitSelect,
      dogetRegion,
      canSelectRegion,
      regionLst,
      programTypeList,
      customerTypeList,
      reasonList,
      goToSetup,
      reset,
      renderDealer,
      renderDealerName,
      renderEligibleProgram,
      deleteRecord,
      getBuName,
    };
  },
});
