import { fetchGet, fetchPost, fetchDelete } from '../index';

//生成SICCO Checing Deatail的列表
export const getSICCOChecingDetailList = (params = {}): Promise<any> => {
    // return fetchGet('/claimapi/dealer/doc/save', {params})
    return fetchPost('/claimapi/checking/page', params);
};

//获取delay call back rule history列表
export const getDelayCallBackRuleHistory = (): Promise<any> => {
    return fetchGet(`/claimapi/callback/rule/list`);
};
//获取activePeriod列表
export const getPeriody = (params = { type: '' }): Promise<any> => {
    return fetchGet(
        `/claimapi/callback/rule/activePeriod/${params.type}`,
        params
    );
};
//Add操作
export const addCallBackRule = (params = {}): Promise<any> => {
    return fetchPost(`/claimapi/callback/rule/add`, params);
};

//Close操作
export const closeRuleHistory = (params = { id: '' }): Promise<any> => {
    return fetchPost(`/claimapi/callback/rule/close/${params.id}`, params);
};

//获取exemption列表
export const getExemptionLst = (params: any): Promise<any> => {
    return fetchGet(`/claimapi/exemption/page`, { params });
};

//获取activePeriod列表
export const exemptionDownload = (id: string): Promise<any> => {
    return fetchGet(`/claimapi/exemption/download/${id}`);
};

// 删除一条数据
export const exemptionDelete = (id: string): Promise<any> => {
    return fetchDelete(`/claimapi/exemption/delete/${id}`);
};
